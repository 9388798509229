import { IonIcon } from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import React from "react";
import styles from './Cashback.module.scss';

type Props = {
  rewardText: any;
  styleName: any;
};
export const Cashback = ({ rewardText, styleName }: Props) => {
  return (rewardText?.displayText && <div className={`${styles[styleName]} ion-no-margin ion-no-padding`}>
    <IonIcon icon={walletOutline} className={rewardText?.noCashback === 1 ? 'icon-danger-color' : 'icon-special-color'}></IonIcon>
    <p className={rewardText?.noCashback === 1 ? `card-danger-text-color` : `card-special-text-color`}>{rewardText?.displayText}</p>
  </div>);
};


