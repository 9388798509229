import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router";

type ProfileContextType = {
  profileid: string | null;
  setProfileId: any;
};
const UserContext = createContext<ProfileContextType | null>(null);

const ProfileIdProvider = ({ children }: any) => {
  const history = useHistory();
  const userProfileId: any = new URLSearchParams(history.location.search).get(
    "profile_id"
  );

  if (!localStorage.getItem("userId")) {
    if (userProfileId) {
      localStorage.setItem("userId", userProfileId);
    }
  } else if (userProfileId !== null) {
    localStorage.setItem("userId", userProfileId);
  }

  const user_Id = localStorage.getItem("userId");
  const [profileid, setProfileId] = useState<any>(user_Id);

  return (
    <UserContext.Provider value={{ profileid, setProfileId }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export { useUser, ProfileIdProvider };