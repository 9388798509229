import { IonContent, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { wallet, logOut, logIn } from "ionicons/icons";
import styles from "./AccountPopoverContent.module.scss";
import { useHistory } from "react-router";

export const AccountPopoverContent = ({ closePopOver }: any) => {
  const history = useHistory();
  const isAuthenticated = () => {
    const profileData: any = JSON.parse(
      localStorage.getItem("profile") || "{}"
    );
    if (profileData?.token) {
      return true;
    }
    return false;
  };
  return (
    <IonContent className={`${styles.accountPopover}`}>
      {isAuthenticated() ? (
        <div>
          <IonItem
            button
            detail={false}
            onClick={() => {
              history.replace("/cashback");
              closePopOver();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={wallet} color="success" />
            <IonLabel className="ion-no-border">Cashback</IonLabel>
          </IonItem>
          <IonItem
            button
            detail={false}
            onClick={() => {
              localStorage.clear();
              closePopOver();
              window.location.reload();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={logOut} color="danger" />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </div>
      ) : (
        <div>
          <IonItem
            button
            detail={false}
            onClick={() => {
              history.replace("/login");
              closePopOver();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={logIn} color="success" />
            <IonLabel className="ion-no-border">Login</IonLabel>
          </IonItem>
        </div>
      )}
    </IonContent>
  );
};
