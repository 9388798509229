import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PartnerContextProvider } from './Contexts/PartnerContext';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <PartnerContextProvider>
        <App />
    </PartnerContextProvider>
);


serviceWorkerRegistration.unregister();


reportWebVitals();
