import { IonTitle, IonList, IonItem, IonCheckbox, IonLabel, IonRadio, IonRadioGroup, IonButton, IonCol, IonGrid, IonRow } from "@ionic/react"
import styles from './SortFilter.module.scss'
import { useHistory } from "react-router"
import { useEffect, useState } from "react";

export const SortFilter = (props: any) => {
  const sortData = [
    { 'Relevance': 'relevance' },
    { 'Popular': 'popular' },
    { 'Price Low': 'pricelow' },
    { 'Price High': 'pricehigh' },
    { 'Discount Low': 'discountlow' },
    { "Discount High": 'discounthigh' }
  ];
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState<any>({});
  const [selectedSort, setSelectedSort] = useState();
  const [appliedFilter, setAppliedFilter] = useState(props.appliedFilter);
  const [appliedSort, setAppliedSort] = useState(props.appliedSort);
  const [finalFilter, setFinalFilter] = useState({});
  const searchParam = new URLSearchParams(history.location.search).get('q');
  useEffect(() => {
    if (Object.keys(appliedFilter).length !== 0) {
      setSelectedFilter(Object.entries(appliedFilter).reduce((prev: any, curr: any) => {
        if (Array.isArray(curr[1])) {
          return appliedFilter
        }
        else {
          return ({ ...prev, [curr[0]]: curr[1].split(',') })
        }
      }, {}))
    }

  }, [appliedFilter]);

  useEffect(() => {
    if (appliedSort) {
      setSelectedSort(appliedSort);
    }
  }, [appliedSort])

  useEffect(() => {
    const removeEmptyArray = Object.entries(selectedFilter).filter(
      (item: any) => item[1].length !== 0
    );
    if (removeEmptyArray.length !== 0) {
      const selectedFilterObject = removeEmptyArray.reduce(
        (prev: any, item: any) => ({
          ...prev,
          [item[0]]: item?.[1]?.map((mapItem: any) => mapItem).join(","),
        }),
        {}
      );
      setFinalFilter(selectedFilterObject);
      if (selectedFilter) {
        if (selectedSort !== 'relevance' && selectedSort) {
          if (searchParam) {
            history.replace({ search: `q=${searchParam}&filter=${encodeURIComponent(JSON.stringify(selectedFilterObject))}&sort=${selectedSort}` })
          }
          else {
            history.replace({ search: `filter=${encodeURIComponent(JSON.stringify(selectedFilterObject))}&sort=${selectedSort}` })
          }
        }
        else {
          if (searchParam) {
            history.replace({ search: `q=${searchParam}&filter=${encodeURIComponent(JSON.stringify(selectedFilterObject))}` })
          } else {
            history.replace({ search: `filter=${encodeURIComponent(JSON.stringify(selectedFilterObject))}` })
          }
        }
      }
      else {
        if (selectedSort) {
          console.log(searchParam);
          if (searchParam) {
            history.replace({ search: `q=${searchParam}&sort=${selectedSort}` })
          } else {
            history.replace({ search: `sort=${selectedSort}` })
          }
        }
      }
    }
    else if (selectedSort && selectedSort !== 'relevance') {
      if (searchParam) {
        history.replace({ search: `q=${encodeURIComponent(searchParam)}&sort=${selectedSort}` });
      }
      else {
        history.replace({ search: `sort=${selectedSort}` });
      }
    }
    else if (searchParam) {
      history.replace({ search: `q=${searchParam}` });
    }
    else {
      history.replace({});
    }
  }, [selectedFilter])


  useEffect(() => {
    if (selectedSort) {
      if (selectedSort === 'relevance') {
        if (searchParam) {
          if (Object.entries(appliedFilter).length !== 0) {
            history.replace({ search: Object.entries(appliedFilter).length !== 0 ? `q=${searchParam}&filter=${encodeURIComponent(JSON.stringify(appliedFilter))}` : `` })
          }
          else {
            history.replace({ search: `q=${searchParam}` })
          }
        } else {
          if (Object.entries(appliedFilter).length !== 0) {
            history.replace({ search: Object.entries(appliedFilter).length !== 0 ? `filter=${encodeURIComponent(JSON.stringify(appliedFilter))}` : `` })
          }
          else {
            history.replace({ search: Object.entries(finalFilter).length !== 0 ? `filter=${encodeURIComponent(JSON.stringify(finalFilter))}` : `` })
          }
        }
      }
      else {
        if (searchParam) {
          if (Object.entries(appliedFilter).length !== 0) {
            history.replace({ search: Object.entries(appliedFilter).length !== 0 ? `q=${searchParam}&filter=${encodeURIComponent(JSON.stringify(appliedFilter))}&sort=${selectedSort}` : `q=${searchParam}&sort=${selectedSort}` })
          }
          else {
            history.replace({ search: Object.entries(finalFilter).length !== 0 ? `q=${searchParam}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}&sort=${selectedSort}` : `q=${searchParam}&sort=${selectedSort}` })
          }
        }
        else {
          if (Object.entries(appliedFilter).length !== 0) {
            history.replace({ search: Object.entries(appliedFilter).length !== 0 ? `filter=${encodeURIComponent(JSON.stringify(appliedFilter))}&sort=${selectedSort}` : `sort=${selectedSort}` })
          }
          else {
            history.replace({ search: Object.entries(finalFilter).length !== 0 ? `filter=${encodeURIComponent(JSON.stringify(finalFilter))}&sort=${selectedSort}` : `sort=${selectedSort}` })
          }
        }
      }
    }
  }, [selectedSort])

  const getCheckedItem = (event: any, displayCategory: any, item: any) => {
    if (Object.keys(selectedFilter).length === 0) {
      setSelectedFilter({ [displayCategory]: [item] });
    } else {
      if (selectedFilter[displayCategory]) {
        if (event.target.checked) {
          setSelectedFilter({
            ...selectedFilter,
            [displayCategory]: [...selectedFilter[displayCategory], item],
          });
        } else {
          setSelectedFilter({
            ...selectedFilter,
            [displayCategory]: selectedFilter[displayCategory].filter(
              (filterItem: any) => filterItem !== item
            ),
          });
        }
      } else {
        setSelectedFilter({ ...selectedFilter, [displayCategory]: [item] });
      }
    }
  };


  const isChecked = (category: any, item: any) => {
    if (selectedFilter && Object.keys(selectedFilter).length !== 0) {
      if (selectedFilter[category]) {
        return selectedFilter[category].includes(item);
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  const filterData = (data: any) => {
    if (data?.values) {
      return data?.values?.map((item: any) => item)
    } else if (data?.range) {
      return data?.range?.map((item: any) => item)
    } else {
      return data?.facets?.map((item: any) => item?.name)
    }
  }

  const clearFilter = () => {
    setFinalFilter({});
    setSelectedFilter({})
    if (selectedSort) {
      if (searchParam) {
        history.replace({ search: `q=${searchParam}&sort=${selectedSort}` })
      } else {
        history.replace({ search: `sort=${selectedSort}` })
      }
    } else if (searchParam) {
      if (selectedSort) {
        history.replace({ search: `q=${searchParam}&sort=${selectedSort}` })
      } else {
        history.replace({ search: `q=${searchParam}` })
      }
    } else {
      history.replace({})
    }
  }

  return (
    <div className={styles.filterContainer}>
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-no-padding ion-padding-bottom ion-align-items-center">
          <IonCol>
            <IonTitle className={`ion-no-padding font16`}>
              Filter By
            </IonTitle>
          </IonCol>
          <IonCol size='6'>
            <IonButton onClick={() => clearFilter()} className={`ion-no-margin`}>
              CLEAR ALL
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      {
        props?.data?.length !== 0 && props?.data !== undefined && props?.data?.map((filter: any, index: any) =>
          <div key={index}>
            <IonTitle className={`${styles.title} ion-no-padding font14`}>
              {filter.displayName}
            </IonTitle>
            <IonList className="ion-no-padding">
              {
                filterData(filter)?.map((item: any, index: number) =>
                  <IonItem className="ion-bg-white ion-no-padding" lines="none" key={index}>
                    <IonCheckbox mode="ios" className="checkbox" checked={isChecked(filter?.displayName, item)} onClick={(event) => { getCheckedItem(event, filter?.displayName, item) }} />
                    <IonLabel className={`font12 ion-text-capitalize`}>
                      {item}
                    </IonLabel>
                  </IonItem>
                )
              }
            </IonList>
          </div>
        )
      }
      <IonTitle className={`${styles.title} ion-no-padding font14`}>
        Sort By
      </IonTitle>
      <IonRadioGroup className="ion-padding padl10" allowEmptySelection={false} value={selectedSort || 'relevance'}>
        {
          sortData?.map((item: any, id: number) => {
            return Object.entries(item)?.map((items: any, index: number) =>
              <div key={index} className={`d-flex ion-align-items-center marb10 padl10 ${id === 0 ? 'ion-no-padding padl10' : ''}`}>
                <IonRadio value={items[1]} onClick={() => setSelectedSort(items[1])} ></IonRadio>
                <IonLabel className={`font12 ion-text-capitalize ${styles.padl6}`}>{items[0]}</IonLabel>
              </div>
            )
          }
          )
        }
      </IonRadioGroup>
    </div>
  )
}