import { IonGrid, IonRow, IonCol, IonSearchbar, IonContent, IonPage, IonLoading } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BannerCarousel, CircleScrollbar, Footer, Header, TabHome } from "../../../components";
import { ProductCategory } from "../shopping-components/product-category-home/ProductCategory";
import { NoData } from "../../../components/index";
import { getShoppingData } from "../../../utils/getApiData";
import styles from "./ShoppingHome.module.scss";
import { useUser } from "../../../Contexts/UserContext";
import { StoreCarousel } from "../../deals/deal-components";
import ReactGA from "react-ga4";
import { googleAnalyticsSearchTerm } from "../../../utils/reactGa";
import { HeaderLogoPopoverComponent } from "../../../components/HeaderLogoPopoverComponent/HeaderLogoPopoverComponent";

export const ShoppingHome = () => {
  const [shoppingData, setShoppingData] = useState<any>();
  const [showLoading, setShowLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const history = useHistory()
  const categoryId = new URLSearchParams(history.location.search).get("id");
  const user = useUser();

  const RequestParams = {
    sendSelectedTab: 1,
    page: 1,
    ...(!categoryId && { pageType: 'home', type: "shopping" }),
    ...(categoryId && { tabId: Number(categoryId) || 191, }),
    ...(user?.profileid && { profileid: user?.profileid })
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: history.location.pathname + history.location.search,
    });
  }, [history]);

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      try {
        const {
          data: { selectedTabItems },
        } = await getShoppingData(RequestParams);
        setShoppingData(selectedTabItems);
      } catch {
        setNoData(true);
      }
      setShowLoading(false);
    })();
  }, [categoryId]);

  const searchHandler = (event: any) => {
    if (event.key === "Enter" && event.target.value !== "") {
      googleAnalyticsSearchTerm(
        event.target.value,
        history.location.pathname.slice(1)
      );
      history.push({
        pathname: `/shopping/search/`,
        search: `q=${encodeURIComponent(event.target.value)}`,
      });
    }
  };

  return (
    <IonPage className={styles.shoppingHome}>
      <HeaderLogoPopoverComponent />
      <TabHome />
      <Header data={shoppingData} searchBar={false} />
      {noData ? (
        <NoData props={"No Data Found !"} />
      ) : (
        <IonContent className={styles.content}>
          <IonGrid fixed className={styles.contentGrid}>
            <IonRow>
              <IonCol>
                {shoppingData &&
                  shoppingData?.map((category: any, index: number) => {
                    switch (category.type) {
                      case "searchBar":
                        return (
                          <div
                            className={`${styles.searchBarContainer} ion-hide-sm-up`}
                            key={index}
                          >
                            <IonSearchbar
                              key={index}
                              placeholder="Search for Products,Brand and More"
                              className="search-border "
                              onKeyPress={(event: any) => searchHandler(event)}
                            />
                          </div>
                        );
                      case "mini_banners":
                        return (
                          <CircleScrollbar
                            mini_banners={category.mini_banners}
                            url="shopping"
                            key={index}
                          />
                        );
                      case "banners":
                        return <BannerCarousel props={category} key={index} />;
                      case "stores":
                        return <StoreCarousel props={category} key={index} />;
                      case "deals": {
                        return (
                          <ProductCategory category={category} key={index} />
                        );
                      }
                      case "products": {
                        return (
                          <ProductCategory category={category} key={index} />
                        );
                      }
                    }
                  })}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      )}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />
     
    </IonPage>
  );
};
