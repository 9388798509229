// EasiDesi Thumbnail
export const esdBannerThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/Banner.png"
export const esdCategoryThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/categoryIcon.png"
export const esdDealCardThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/dealCard.png"
export const esdDealListCardThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/dealListCard.png"
export const esdDealDetailThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/dealDetail.png"
export const esdCouponCardThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/couponCard.png"
export const esdCouponDetailThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/Coupondetail.png"
export const esdShoppingCardThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/shoppingCard.png"
export const esdShoppingListCardThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/shoppingListCard.png"
export const esdShoppingDetailThumbnail = "https://img.travelx.ai/Easidesi/thumbnail/shoppingDetail.png"

// Common Thumbnail
export const bannerThumbnail  = 'assets/Image/bannerThumbnail.png'
export const productThumbnail = 'assets/Image/productThumbnail.png'
export const categoryThumbnail = 'assets/Image/categoryThumbnail.png'