import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronBack } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import { Footer, Header, NoData } from "../../../components";
import { getCouponDetail } from "../../../utils/getApiData";
import {
  esdCouponDetailThumbnail,
  productThumbnail,
} from "../../../thumbnailConfig";
import styles from "./CouponDetail.module.scss";
import { useUser } from "../../../Contexts/UserContext";
import ReactGA from "react-ga4";
import { googleAnalyticsViewStore } from "../../../utils/reactGa";
import { usePartner } from "../../../Contexts/PartnerContext";

export interface AndroidInterface {
  onCopyToClipboard(text: string): any;
}
declare const Android: AndroidInterface;

export const CouponDetail = () => {
  const [couponDetail, setCouponDetail] = useState<any>([]);
  const [noData, setNoData] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const { partnerPid } = usePartner();
  let paramId: { id: string } = useParams();
  let couponId = +paramId.id;
  const history = useHistory();

  const RequestParams = {
    id: couponId,
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: history.location.pathname + history.location.search,
    });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { coupon },
        } = await getCouponDetail(RequestParams);
        setCouponDetail(coupon);
      } catch {
        setNoData(true);
      }
      setShowLoading(false);
    })();
  }, []);

  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  function handleCopyAndRedirect(code: any, url: any) {
    if (typeof Android !== "undefined") {
      Android.onCopyToClipboard(code);
    } else if (isIOS()) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          window.location.href = url;
        })
        .catch((err) => {
          console.error("Error copying text to clipboard:", err);
        });
    } else {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.error("Error copying text to clipboard:", err);
        });
    }
  }

  const gaHandler = (coupon: any) => {
    googleAnalyticsViewStore(coupon.logParams, coupon?.landing_url);
  };

  const handleImageFailure = (e: any) => {
    e.target.src =
      partnerPid === "esd" ? esdCouponDetailThumbnail : productThumbnail;
  };

  return (
    <IonPage className={styles.couponDetail}>
      <Header searchBar={true} />
      <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed>
            <IonRow>
              <IonButtons>
                <IonBackButton
                  icon={chevronBack}
                  defaultHref="/coupons"
                ></IonBackButton>
              </IonButtons>
              <IonTitle className={`${styles.couponDetailTitle} font16`}>
                COUPON
              </IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      {noData ? (
        <>
          <div className="scrollableDesign ion-hide-sm-up">
            <div className="scrollable"></div>
          </div>
          <NoData props={"No Data Found !"} />
        </>
      ) : (
        <IonContent className={`ion-justify-content-center ${styles.content}`}>
          <div className="scrollableDesign ion-hide-sm-up">
            <div className="scrollable"></div>
          </div>
          <IonGrid className={`ion-no-padding ${styles.contentGrid}`} fixed>
            <IonRow class="ion-justify-content-center">
              <IonCol size="12" size-sm="6" size-md="6" size-lg="4" size-xl="4">
                {couponDetail && (
                  <IonCard className="ion-justify-content-center ion-margin">
                    <div className={styles.productImg}>
                      <img
                        src={couponDetail?.storeLogo}
                        alt="product"
                        onError={(e) => handleImageFailure(e)}
                      />
                    </div>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-center font12  card-title-color">
                        {couponDetail?.couponTitle}
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-center">
                      <IonText className="coupon-code ion-no-padding card-primary-text-color">
                        {couponDetail?.couponCode}
                      </IonText>
                      <div>
                        <IonButton
                          onClick={() => {
                            gaHandler(couponDetail);
                            handleCopyAndRedirect(
                              couponDetail?.couponCode,
                              couponDetail?.landing_url
                            );
                          }}
                          className="ion-margin-vertical ion-text-uppercase font10 secondary-btn"
                          shape="round"
                        >
                          Copy Code And Shop
                        </IonButton>
                      </div>
                      <div className="font8 card-grey-text-color">
                        {couponDetail?.couponDescription}
                      </div>
                    </IonCardContent>
                  </IonCard>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
          {couponDetail.length > 0 && <Footer />}
        </IonContent>
      )}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />
    </IonPage>
  );
};
