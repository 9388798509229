import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonToolbar,
  IonSearchbar,
  IonProgressBar,
  IonList,
  IonItem,
  IonNote,
  IonLabel,
  IonModal,
} from "@ionic/react";
import countryMeta from "../../../DataFiles/country-meta";

import styles from "./CountryCodeModal.module.scss";

interface CountryCodeModalProps {
  isOpen: boolean;
  onClose: (data?: any) => void;
  selectCountry: (data?: any) => void;
}

const CountryCodeModal: React.FC<CountryCodeModalProps> = ({
  isOpen,
  onClose,
  selectCountry,
}) => {
  const [countryCodes, setCountryCodes] = useState<any>([]);

  useEffect(() => {
    setCountryCodes(countryMeta);
  }, [isOpen]);

  const selectCountryCode = (item: any) => {
    selectCountry(item);
  };

  const filterCountrylist = (event: any) => {
    const val = event?.target?.value;

    if (val && val.trim() !== "") {
      const filteredCodes = countryMeta
        .filter(
          (item) => item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
        )
        .sort((a, b) => a.name.length - b.name.length);
      setCountryCodes(filteredCodes);
    } else {
      setCountryCodes(countryMeta);
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      className={styles.CountryCodeModal}
    >
      <IonContent>
        <IonToolbar className={styles.toolBar}>
          <IonSearchbar placeholder="Search" onIonInput={filterCountrylist} />
          {!countryCodes && <IonProgressBar type="indeterminate" />}
        </IonToolbar>
        {countryCodes && (
          <IonList lines="none" className="ion-list-transparent-bg-color">
            {countryCodes?.map((item: any, index: any) => (
              <IonItem
                key={index}
                onClick={() => {
                  onClose();
                  selectCountryCode(item);
                }}
                className={styles.countryItem}
              >
                <IonNote
                  slot="start"
                  dangerouslySetInnerHTML={{ __html: item.flag }}
                  className={styles.countryFlags}
                />
                <IonLabel>
                  <h2 className="paragraph-primary-text-color">{item.name}</h2>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonModal>
  );
};

export default CountryCodeModal;
