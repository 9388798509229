import { useState } from "react";
import styles from "./HeaderLogoPopoverComponent.module.scss";
import { IonButton, IonButtons, IonIcon, IonPopover } from "@ionic/react";
import { AccountPopoverContent } from "../AccountPopoverContent/AccountPopoverContent";
import { personCircle } from "ionicons/icons";
import { usePartner } from "../../Contexts/PartnerContext";

export const HeaderLogoPopoverComponent = () => {
  const { imgLogoSrc, showLogin } = usePartner();
  const [popoverState, setShowPopover] = useState<any>({
    showPopover: false,
    event: undefined,
  });

  const closePopOver = () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
  };

  const handlePopoverClick = (e: React.MouseEvent) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };

  return (
    <div className={styles.headerLogoPopover}>
      <div className={`${styles.logoProfileBox} ion-hide-sm-up`}>
        <div className={showLogin ? styles.mshopLogoLogin : styles.mshopLogo}>
          <img src={imgLogoSrc} alt="logo" />
        </div>
        {showLogin && (
          <IonButtons slot="end">
            <IonButton onClick={handlePopoverClick}>
              <IonIcon slot="icon-only" icon={personCircle} />
            </IonButton>
          </IonButtons>
        )}
      </div>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className={`${styles.popOver}`}
      >
        <AccountPopoverContent closePopOver={closePopOver} />
      </IonPopover>
    </div>
  );
};
