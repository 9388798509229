import { IonFooter, IonImg } from '@ionic/react';
import React from 'react';
import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <span className='primary-title-color'>Powered by</span>
      <IonImg src='assets/icon/scandidlogo.png'></IonImg>
    </div>
  );
};
