import { IonGrid, IonRow, IonCol, IonSearchbar, IonContent, IonPage, IonLoading, IonFooter } from "@ionic/react"
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DealCategory, StoreCarousel, } from "../deal-components/index.js";
import { BannerCarousel, CircleScrollbar, TabHome, NoData, Footer } from "../../../components/index.js";
import { getAllDeals } from "../../../utils/getApiData";
import { useUser } from '../../../Contexts/UserContext'
import styles from './DealHomePage.module.scss'
import ReactGA from 'react-ga4'
import { googleAnalyticsSearchTerm } from "../../../utils/reactGa";
import { Header } from "../../../components/index.js";
import { HeaderLogoPopoverComponent } from "../../../components/HeaderLogoPopoverComponent/HeaderLogoPopoverComponent";

export const DealHomePage = () => {
    const [dealsData, setdealsData] = useState<any>()
    const [showLoading, setShowLoading] = useState(true);
    const history = useHistory();
    const [noData, setNoData] = useState(false);
    const categoryId = new URLSearchParams(history.location.search).get("id");
    const user = useUser();

    const RequestParams = {
        sendSelectedTab: 1,
        page: 1,
        ...(!categoryId && { pageType: 'home', type: "deals" }),
        ...(categoryId && { tabId: Number(categoryId) || 191, }),
        ...(user?.profileid && { profileid: user?.profileid })
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
    }, [history]);

    useEffect(() => {
        (async () => {
            setShowLoading(true)
            try {
                const { data: { selectedTabItems } } = await getAllDeals(RequestParams)
                setdealsData(selectedTabItems)
            }
            catch (err) {
                setNoData(true);
            }
            setShowLoading(false)
        })();
    }, [categoryId])

    const searchHandler = (event: any) => {
        if (event.key === "Enter" && event.target.value !== "") {
            googleAnalyticsSearchTerm(event.target.value, history.location.pathname.slice(1));
            history.push({ pathname: `/deals/search/`, search: `q=${encodeURIComponent(event.target.value)}` })
        }
    }

    return (
        <IonPage className={`${styles.dealHomePage}`}>
            <Header data={dealsData} searchBar={false}/>
            <HeaderLogoPopoverComponent/>
            <TabHome />
            {
                noData ? <NoData props={"No Data Found !"} /> : (<IonContent className={styles.content}>
                    <IonGrid fixed className={styles.contentGrid}>
                        <IonRow>
                            <IonCol>
                                {
                                    dealsData && dealsData?.map((item: any, index: any) => {
                                        switch (item.type) {
                                            case "searchBar":
                                                return (
                                                    <div className={`${styles.searchBarContainer} ion-hide-md-up`} key={index}>
                                                        <IonSearchbar className={`ion-no-padding`} placeholder={item.display_name} value={""} onKeyPress={(event: any) => searchHandler(event)} ></IonSearchbar>
                                                    </div>
                                                )
                                            case "mini_banners":
                                                return (
                                                    <CircleScrollbar mini_banners={item.mini_banners} url="deals" key={index} />
                                                )
                                            case "banners":
                                                return <BannerCarousel props={item} key={index} />
                                            case "stores":
                                                return <StoreCarousel props={item} key={index} />
                                            case "deals":
                                                return <DealCategory props={item} key={index} />
                                        }
                                    })
                                }
                            </IonCol>
                        </IonRow >
                    </IonGrid >
                </IonContent>)
            }
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                message={'Please wait...'}
            />
        </IonPage>
    )
}