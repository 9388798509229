import { IonCard, IonImg, IonCardHeader, IonCardSubtitle, IonCardContent, IonText, IonCardTitle } from "@ionic/react";
import { esdCouponCardThumbnail, productThumbnail } from "../../../../thumbnailConfig";
import { googleAnalyticsViewItem } from "../../../../utils/reactGa";
import { useHistory } from "react-router";
import { usePartner } from "../../../../Contexts/PartnerContext";
import styles from './CouponListCard.module.scss';

export const CouponListCard = ({ coupon }: any) => {
  const history = useHistory();
  const { partnerPid } = usePartner();
  const gaHandler = (coupon: any) => {
    googleAnalyticsViewItem(coupon?.logParams);
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdCouponCardThumbnail : productThumbnail;
  }

  return (
    <>
      <IonCard
        className={`${styles.couponListCard} ion-padding`}
        onClick={() => { gaHandler(coupon); history.push(`/coupons/${coupon.id}`) }}>
        <div className={styles.productImg}>
          <img src={coupon.storeLogo} alt="product" onError={(e) => handleImageFailure(e)} />
        </div>
        <IonCardHeader className="ion-no-padding ion-margin-vertical">
          <IonCardTitle
            className="ion-text-center line-2-clip font12 card-title-color">
            {coupon.couponTitle}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="ion-text-center ion-no-padding">
          <IonText className="coupon-code ion-no-padding card-primary-text-color">
            {(coupon.couponCode.toUpperCase()) === "NO COUPON REQUIRED" ? 'NO COUPON CODE' : coupon.couponCode}
          </IonText>
          <div className=" font8 ion-margin card-grey-text-color">Copy Coupon Code</div>
        </IonCardContent>
      </IonCard>
    </>
  )
} 