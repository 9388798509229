import { IonGrid, IonImg, IonText } from '@ionic/react'
import styles from './NoData.module.scss'

type Props = {
    props: string
}

export const NoData = ({ props }: Props) => {
    return (
        <IonGrid className={`${styles.nodata}`}>
            <IonImg src='/assets/icon/nodata.png'></IonImg>
            <IonText className='font16 font900'>{props}</IonText>
            {props === "404" && (<IonText className="font16 font900 dark"> Page Not Found </IonText>)}
        </IonGrid>
    )
}
