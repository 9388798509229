import React from "react";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonModal,
  IonIcon,
} from "@ionic/react";
import { close } from "ionicons/icons";

import styles from "./CashbackBalanceDetailModal.module.scss";
import BalanceCard from "../../balanceCard/balanceCard";

interface CashbackBalanceDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const CashbackBalanceDetailModal: React.FC<CashbackBalanceDetailModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      className={styles.cashbackBalanceDetailModal}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Balance Details</IonTitle>
          <IonIcon
            slot="end"
            icon={close}
             className={`${styles.closeIcon}`}
            onClick={onClose}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className={`${styles.balanceCards}`}>
          {data?.map((item: any, index: number) => {
            return (
              <BalanceCard
                key={index}
                text={item?.displayName}
                amount={item?.points}
                openCashbackBalanceDetailModal={() => {}}
                isOpenable={false}
              />
            );
          })}
        </div>
          {(!data || data ===  undefined || data?.length === 0)&& (
            <div className={`${styles.noTransaction}`}>
              <p className="paragraph-primary-text-color font600 font18 ">
                No Details Found !
              </p>
            </div>
          )}
        {/* <IonButton expand="block" onClick={onClose}>
          Close Modal
        </IonButton> */}
      </IonContent>
    </IonModal>
  );
};

export default CashbackBalanceDetailModal;
