import {
    IonCard,
    IonButton,
    IonLabel,
  } from '@ionic/react';
  import React from 'react';
  import { esdDealCardThumbnail, categoryThumbnail } from '../../../thumbnailConfig';
  import styles from './StoreCard.module.scss';
  import { useHistory } from 'react-router';
  import { googleAnalyticsCampaigns } from '../../../utils/reactGa';
  import { usePartner } from '../../../Contexts/PartnerContext';
  
  type Props = {
    props: any;
    index:any
  };
  
  const handleButtonText = (item: any) => {
    if (item) {
      const numberRegex = item.match(/(\d+(\.\d+)?)/);
      const percentageRegex = item.match(/(\d+(\.\d+)?)%/);
      if (percentageRegex) {
        return item.replace(percentageRegex[0], `<span>${percentageRegex[0]}</span>`)
      } else if (numberRegex) {
        return item.replace(numberRegex[0], `<span>${numberRegex[0]}</span>`)
      } else {
        return item;
      }
    }
    else {
      return 'go to store'
    }
  }
  
  export const StoreCard = ({ props,index }: Props) => {
    const { partnerPid } = usePartner();
    const history = useHistory();
    const pagePath = history.location.pathname.substring(1)
    const gaHandler = (item: any, index: number) => {
      googleAnalyticsCampaigns(pagePath, item, index);
    }
  
    const handleImageFailure = (e: any) => {
      e.target.src = partnerPid === 'esd' ? esdDealCardThumbnail : categoryThumbnail;
    }
  
    const pageRedirectHandler = (item: any, index: any) => {
      gaHandler(item, index)
      if (item?.affInfoRedirect === 1) {
        history.push({ pathname: `/commission/${item.name}`, search: `url=${encodeURIComponent(item.url)}` })
      } else {
        window.open(item.url, '_blank')
      }
    }
    return (
        <div className={`${styles.storeInfo}`}>
          <IonCard onClick={() => pageRedirectHandler(props, index)}>
            <div className={styles.imgContainer}>
              <img src={props?.image} alt="deal" onError={handleImageFailure} />
            </div>
            <div className={`${styles.storeName} ion-text-uppercase`}>
              <IonLabel>{props?.name}</IonLabel>
            </div>
            <IonButton className="ion-text-uppercase marl10 marr10 mart10 marb10 secondary-btn" expand="block">
              <div className={`${styles.commission} text-len`} dangerouslySetInnerHTML={{ __html: handleButtonText(props.title) }}></div>
            </IonButton>
          </IonCard>
        </div>
      );
    }