import { IonItem, IonGrid, IonRow, IonCol } from "@ionic/react"
import styles from "./EarningRates.module.scss";

export const EarningRates = ({ data }: any) => {
    return (
        <div className={`${styles.earningRates}`}>
            <IonItem lines="none" className="border-r10 ion-bg-white">
                <div className={`${styles.contentContainer} ion-no-padding ion-no-margin`}>
                    <IonGrid className="ion-no-padding">
                        <IonRow className={`${styles.tableTitle}`}>
                            <IonCol size="7">
                                <h6 className="font10 font600 ion-text-center ">
                                    Product Category
                                </h6>
                            </IonCol>
                            <IonCol size="3">
                                <h6 className="font10 font600 ion-text-center ">
                                    User Type
                                </h6>
                            </IonCol>
                            <IonCol size="2">
                                <h6 className="font10 font600 ion-text-center ">
                                    Earnings
                                </h6>
                            </IonCol>
                        </IonRow>
                        {
                            data && data?.map((item: any, index: number) => {
                                return (
                                    <IonRow key={index} className="ion-align-items-center">
                                        <IonCol size="7">
                                            <h6 className="font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                                {item?.categoryName}
                                            </h6>
                                        </IonCol>
                                        <IonCol size="3">
                                            {
                                                item?.newUserCommissionRate && <IonRow className="ion-justify-content-center  ion-align-items-center">
                                                    <h6 className="font10 font500 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                                        New User
                                                    </h6>
                                                </IonRow>
                                            }
                                            {
                                                item?.existingUserCommissionRate && <IonRow className="ion-justify-content-center  ion-align-items-center">
                                                    <h6 className="font10 font500 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                                        Existing User
                                                    </h6>
                                                </IonRow>
                                            }
                                        </IonCol>
                                        <IonCol size="2">
                                            {
                                                item?.newUserCommissionRate && <IonRow className="ion-justify-content-center ion-align-items-center">
                                                    <h6 className={`font10 font600 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ${styles.newUser}`}>
                                                        {item?.newUserCommissionRate}
                                                    </h6>
                                                </IonRow>
                                            }
                                            {
                                                item?.existingUserCommissionRate && <IonRow className="ion-justify-content-center  ion-align-items-center">
                                                    <h6 className={`font10 font600 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ${styles.existingUser}`}>
                                                        {item?.existingUserCommissionRate}
                                                    </h6>
                                                </IonRow>
                                            }
                                        </IonCol>
                                    </IonRow>
                                )
                            })
                        }
                    </IonGrid>
                </div>
            </IonItem>
        </div>
    )
}