import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonGrid,
  IonRow,
  IonButton,
  IonCol,
  IonLoading,
} from "@ionic/react";
import { close, copyOutline } from "ionicons/icons";
import styles from "./Share.module.scss";
import { useRef, useState } from "react";
import { usePartner } from "../../Contexts/PartnerContext";
import { esdDealDetailThumbnail, bannerThumbnail } from "../../thumbnailConfig";
import { useUser } from "../../Contexts/UserContext";
import { googleAnalyticsShare } from "../../utils/reactGa";
import { getShareUrl } from "../../utils/getApiData";

export interface AndroidInterface {
  onCopyToClipboard(text: string): any;
}
declare const Android: AndroidInterface;

export const ShareProduct = ({ state, data, page }: any) => {
  const user = useUser();
  const [copybtnText, setCopyBtnText] = useState("Copy");
  const [showLoading, setShowLoading] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const { partnerPid } = usePartner();

  const RequestParams = {
    allowDuplicates: false,
    domain: "r.scandid.deals",
  };

  const getShortUrl = async (cmp: string) => {
    setShowLoading(true);
    try {
      if (data.id.indexOf("_") > -1) {
        data.storeLink = data.url;
      }

      const {
        data: { secureShortURL },
      } = await getShareUrl({
        ...RequestParams,
        ...(cmp
          ? {
              originalURL:
                page === "deal"
                  ? `${data?.url}&sharemedium=${cmp}`
                  : `${data?.storeLink}&sharemedium=${cmp}`,
            }
          : { originalURL: page === "deal" ? data?.url : data?.storeLink }),
      });
      setShowLoading(false);
      return secureShortURL;
    } catch (err) {
      setShowLoading(false);
      if (page === "deal") {
        return data?.url;
      } else {
        return data?.storeLink;
      }
    }
  };

  async function handlerRedirect(cmp: string) {
    const shortUrl = await getShortUrl(cmp);
    closeModal();
    getRedirect(cmp, shortUrl);
  }

  function getRedirect(cmp: string, shortUrl: any) {
    let shareLink;
    if (page === "deal") {
      shareLink = data?.url;
    } else {
      shareLink = data?.storeshareLink;
    }
    googleAnalyticsShare(cmp, data?.title, shareLink, user?.profileid);
    switch (cmp) {
      case "whatsapp":
        const wpShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          data?.title
        )}%0A${encodeURIComponent(shortUrl)}`;
        window.open(wpShareUrl);
        break;
      case "facebook":
        const fbShareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          shortUrl
        )}`;
        window.open(fbShareUrl);
        break;
      case "mail":
        const mailShareUrl = `mailto:?body=${encodeURIComponent(
          data?.title
        )}%0A${encodeURIComponent(shortUrl)}`;
        window.open(mailShareUrl);
        break;
      case "twitter":
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          data?.title
        )}%0A${encodeURIComponent(shortUrl)}`;
        window.open(twitterShareUrl);
        break;
    }
  }

  const closeModal = () => {
    modal.current?.dismiss();
    state.setShareModal(false);
    setCopyBtnText("Copy");
    // setCopyUrl('');
  };

  const handleImageFailure = (e: any) => {
    e.target.src =
      partnerPid === "esd" ? esdDealDetailThumbnail : bannerThumbnail;
  };

  const handleShare = async () => {
    let shareLink;
    if (page === "deal") {
      shareLink = data?.url;
    } else {
      shareLink = data?.storeshareLink;
    }
    googleAnalyticsShare("More", data?.title, shareLink, user?.profileid);

    if (navigator.share) {
      try {
        await navigator.share({
          title: data?.title,
          url: await getShortUrl("more"),
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
  };

  async function handleCopyAndRedirect() {
    let shareLink;
    if (page === "deal") {
      shareLink = data?.url;
    } else {
      shareLink = data?.storeshareLink;
    }

    googleAnalyticsShare("Copy", data?.title, shareLink, user?.profileid);
    if (copybtnText === "Copy") {
      const copyUrl = await getShortUrl("copy");
      if (typeof Android != "undefined") {
        Android.onCopyToClipboard(copyUrl);
      } else {
        navigator.clipboard
          .writeText(copyUrl)
          .then(() => {
            setCopyBtnText("Copied");
          })
          .catch((err) => {
            console.error("Error copying text to clipboard:", err);
          });
      }
    }
  }

  return (
    <>
      <IonModal
        isOpen={state.shareModal}
        initialBreakpoint={1}
        breakpoints={[1]}
        handleBehavior="cycle"
        className={styles.modal}
        ref={modal}
        onDidDismiss={closeModal}
      >
        <IonItem class={styles.shareHeader}>
          <div
            className={`${styles.imgContainer} ion-padding-start font14 font500 primary-title-color`}
          >
            <img
              src={data?.img}
              alt="product"
              onError={(e) => handleImageFailure(e)}
            />
          </div>
          <div className={styles.product}>
            <IonLabel
              className={`${styles.productTitle} primary-title-color marl10 marr10 font14 font500`}
            >
              {data?.title}
            </IonLabel>
            <IonLabel className="primary-subTitle-color marl10 marr10 font12 font500">
              By {data?.store}
            </IonLabel>
          </div>
          <IonIcon
            slot="end"
            className="icon-dark-color"
            icon={close}
            onClick={() => {
              closeModal();
            }}
          />
        </IonItem>
        <IonContent
          className={`${styles.content} ion-no-padding ion-padding-horizontal ion-padding-top`}
        >
          <IonGrid class="ion-no-padding">
            <IonRow class="ion-justify-content-between">
              <IonCol sizeMd="3">
                <div
                  className="d-flex flex-column ion-align-items-center"
                  onClick={() => handlerRedirect("whatsapp")}
                >
                  <img src="assets/socialIcon/whatsapp.png" alt="whatsApp" />
                  <IonLabel className="font12 font500 marb10">
                    WhatsApp
                  </IonLabel>
                </div>
              </IonCol>
              <IonCol sizeMd="3">
                <div className="d-flex flex-column ion-align-items-center">
                  <img
                    src="assets/socialIcon/facebook.png"
                    alt="facebook"
                    onClick={() => handlerRedirect("facebook")}
                  />
                  <IonLabel className="font12 font500 marb10">
                    Facebook
                  </IonLabel>
                </div>
              </IonCol>
              <IonCol sizeMd="3">
                <div className="d-flex flex-column ion-align-items-center">
                  <img
                    src="assets/socialIcon/gmail.png"
                    alt="Mail"
                    onClick={() => handlerRedirect("mail")}
                  />
                  <IonLabel className="font12 font500 marb10">Gmail</IonLabel>
                </div>
              </IonCol>
              <IonCol sizeMd="3">
                <div className="d-flex flex-column ion-align-items-center">
                  <img
                    src="assets/socialIcon/twitter.png"
                    alt="Twitter"
                    onClick={() => handlerRedirect("twitter")}
                  />
                  <IonLabel className="font12 font500 marb10">Twitter</IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeMd="3" className={styles.moreContainer}>
                <div
                  className={`${styles.more} d-flex flex-column ion-align-items-center`}
                  onClick={() => handleShare()}
                >
                  <span>...</span>
                  <IonLabel className="font12 font500 marb10">More</IonLabel>
                </div>
              </IonCol>
              <IonCol sizeMd="3"></IonCol>
              <IonCol sizeMd="3"></IonCol>
              <IonCol sizeMd="3"></IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="white-btn ion-margin-top"
            expand="block"
            onClick={() => handleCopyAndRedirect()}
          >
            <div className="w100 d-flex ion-justify-content-between">
              <IonLabel class="font12 font500 primary-title-color">
                {copybtnText}
              </IonLabel>
              <IonIcon className="dark" icon={copyOutline}></IonIcon>
            </div>
          </IonButton>
        </IonContent>
      </IonModal>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading && state?.shareModal}
        message={"Please wait..."}
        duration={3000}
      />
    </>
  );
};
