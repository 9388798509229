import React from "react";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { cash } from "ionicons/icons";
import styles from "./CashbackCard.module.scss";

interface CashbackCardProps {
  merchant: string;
  date: string;
  amount: number;
  logo: string;
}

const CashbackCard: React.FC<CashbackCardProps> = ({
  merchant,
  date,
  amount,
  logo,
}) => {
  return (
    <IonCard className={`${styles.cashbackCard}`}>
      <IonCardContent>
        <IonRow className="ion-align-items-center">
          <IonCol size="auto">
            {/* <div className={`${styles.merchantIcon}`}>{merchant?.[0]}</div> */}
            <IonImg src={logo} className={`${styles.logo}`} />
          </IonCol>
          <IonCol>
            <IonText color="dark" className={`${styles.pascalCase}`}>
              <h2>{merchant}</h2>
            </IonText>
            <IonText color="medium">
              <p>{date}</p>
            </IonText>
          </IonCol>
          <IonCol size="auto" className={`${styles.amountCol}`}>
            <IonIcon icon={cash} color="success" />
            
            <IonText color="success">
              <h2>Rs. {amount ? amount?.toFixed(2) : "0.0"}</h2>
            </IonText>
            <IonText color="medium">
              <p>Cashback</p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default CashbackCard;