export const ESD_API_DOMAIN = "https://scandid.in";
export const GET_SHORT_URL = "/api/getShortLinks";
export const GET_APP_PAGE = "/api/getAppPage";
export const GET_DEAL = "/api/getdeal"
export const GET_SHOPPING_LIST = "/api/getBrowseData"
export const GET_SHOPPING_APPLIANCES_Detail_ = "/api/searchV2"
export const GET_SHOPPING_Detail = "/api/getProductDetail"
export const GET_STORE_AFFILIATE = "/api/GetStoreAffiliateInfo"
export const SEND_WEB_OTP = "/api/sendWebOTP"
export const VERIFY_OTP = "/api/verifyOTP"
export const GET_POINTS = "/api/getPoints"
export const GET_COUPON = "/api/getcoupon"
