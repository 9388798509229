import React, { useEffect, useState } from "react";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonImg,
  IonInput,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
} from "@ionic/react";
import styles from "./Login.module.scss";
import "./Login.module.scss";
import { usePartner } from "../../Contexts/PartnerContext";
import { sendOTP, verifyOTP } from "../../utils/getApiData";
import { useHistory } from "react-router-dom";
import CountryCodeModal from "../../components/Modals/CountryCodeModal/CountryCodeModal";
import { useUser } from "../../Contexts/UserContext";

interface NumberSubmitResponse {
  otpId: string;
  msgId: string;
  mobileno: string;
  [key: string]: any;
}

const Login = () => {
  const history = useHistory();
  const { webLogoSrc } = usePartner();
  const [isEmail, setIsEmail] = useState(true);
  const [mobNo, setMobNo] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    code: "IN",
    dialCode: "+91",
    flag: "🇮🇳",
    name: "India",
  });
  const [showModal, setShowModal] = useState(false);
  const user = useUser();

  const presentModal = () => {
    setShowModal(true);
  };

  const handleModalDismiss = () => {
    setShowModal(false);
  };

  const selectCountry = (data: any) => {
    if (data?.dialCode) {
      setSelectedCountry(data);
    }
  };

  const [numberSubmitRes, setNumberSubmitRes] =
    useState<NumberSubmitResponse | null>(null);

  const [errors, setErrors] = useState({
    mobileNo: {
      show: false,
      message: "",
    },
    otp: {
      show: false,
      message: "",
    },
  });

  const sendResendOTP = async (mobileNo: any) => {
    setShowLoader(true);
    try {
      const { data } = await sendOTP(
        { mobnum: mobileNo, callingcode: selectedCountry?.dialCode },
        {}
      );
      if (data?.statusInfo === "Success") {
        setNumberSubmitRes(data);
        setIsEmail(false);
      } else if (data?.status === -1) {
        setErrors((prevState) => ({
          ...prevState,
          mobileNo: {
            show: true,
            message: data?.statusInfo + ". Please try after some time",
          },
        }));
      } else if (data?.status === "-1") {
        setErrors((prevState) => ({
          ...prevState,
          mobileNo: {
            show: true,
            message: "Please verify Country Code and Mobile Number",
          },
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          mobileNo: {
            show: true,
            message: data?.reason || data?.message,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const onSubmitNumber = async (e: any) => {
    e.preventDefault();
    const mobileNo = e?.target?.mobileNo?.value;
    setMobNo(mobileNo);
    sendResendOTP(mobileNo);
  };

  const onSubmitOTP = async (e: any) => {
    e.preventDefault();
    const otp = e?.target?.otp?.value;
    const payload = {
      otpid: numberSubmitRes?.otpId,
      msgid: numberSubmitRes?.msgId,
      mobnum: numberSubmitRes?.mobileno || mobNo,
      otp: otp,
    };
    setShowLoader(true);
    try {
      const { data } = await verifyOTP(payload, {});
      if (data?.token) {
        localStorage.setItem("profile", JSON.stringify(data));
        user?.setProfileId(data?.profileid);
        localStorage.setItem("userId", data?.profileid);
        history.replace(`/shopping`);
      } else {
        setErrors((prevState) => ({
          ...prevState,
          otp: {
            show: true,
            message: data?.reason,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const profileData: any = JSON.parse(
      localStorage.getItem("profile") || "{}"
    );
    const currPid = localStorage.getItem("pid");
    if (currPid ===  "uae") {
      setSelectedCountry({
        name: "United Arab Emirates",
        flag: "🇦🇪",
        code: "AE",
        dialCode: "+971",
      });
    }
    if (profileData?.token) {
      history.replace(`/`);
    }
  }, []);

  return (
    <IonPage className={styles.login}>
      <IonCard className={styles.container}>
        <IonCard className={styles.loginCard}>
          <IonCardHeader className={styles.logoContainer}>
            <IonImg className={`${styles.logo}`} src={webLogoSrc}></IonImg>
          </IonCardHeader>

          {isEmail && (
            <IonCardContent class="ion-no-padding">
              <form onSubmit={onSubmitNumber} className={styles.loginForm}>
                <div className={styles.inputGroup}>
                  <IonLabel className={`${styles.label} primary-title-color`}>
                    Enter your mobile number
                  </IonLabel>
                  <div className={styles.combo}>
                    <IonButton
                      onClick={() => {
                        presentModal();
                      }}
                      className={`${styles.countryButton} primary-border-btn`}
                    >
                      <IonNote
                        slot="start"
                        dangerouslySetInnerHTML={{
                          __html: selectedCountry?.flag,
                        }}
                        className={styles.countryFlags}
                      />
                      <IonLabel>{selectedCountry?.dialCode}</IonLabel>
                    </IonButton>
                    <IonInput
                      id="mobileNo"
                      type="tel"
                      aria-label="mobile no"
                      name="mobileNo"
                      required
                      placeholder="Enter Number"
                      className={styles.input}
                    />
                  </div>
                </div>
                {errors.mobileNo.show && (
                  <IonLabel className={`${styles.errorLabel} danger`}>
                    {errors.mobileNo.message}
                  </IonLabel>
                )}
                <IonButton type="submit"> Login/Register </IonButton>
                <IonButton
                  className="clear-btn"
                  onClick={() => {
                    history.replace(`/shopping`);
                  }}
                >
                  Continue without Login
                </IonButton>
              </form>
            </IonCardContent>
          )}

          {!isEmail && (
            <IonCardContent>
              <form onSubmit={onSubmitOTP} className={styles.loginForm}>
                <div className={styles.inputGroup}>
                  <IonLabel className={`${styles.label} primary-title-color`}>
                    Enter OTP :
                  </IonLabel>
                  <IonInput
                    id="otp"
                    type="tel"
                    name="otp"
                    required
                    placeholder="Enter OTP"
                    className={`${styles.input} ${styles.otpInput}`}
                    min="0"
                    onIonInput={(e) => {
                      e.preventDefault();
                      const input = e.target as HTMLIonInputElement;
                      let value = input.value as string;
                      value = value.replace(/[^0-9]/g, "");
                      value = value.replace(/^0+/, "");
                      input.value = value;
                    }}
                  />
                </div>
                {errors.otp.show && (
                  <IonLabel className={`${styles.errorLabel} danger`}>
                    {errors.otp.message}
                  </IonLabel>
                )}
                <IonButton type="submit">SUBMIT</IonButton>
                <IonButton
                  className="primary-border-btn"
                  onClick={() => {
                    sendResendOTP(mobNo);
                  }}
                >
                  Resend OTP
                </IonButton>
              </form>
            </IonCardContent>
          )}
        </IonCard>
      </IonCard>
      <IonLoading cssClass="my-custom-class" isOpen={showLoader} message={""} />
      <CountryCodeModal
        isOpen={showModal}
        onClose={handleModalDismiss}
        selectCountry={selectCountry}
      />
    </IonPage>
  );
};

export default Login;
