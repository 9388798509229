import { IonButton, IonImg, IonTitle } from "@ionic/react";
import { useHistory } from "react-router";
import { esdCategoryThumbnail, categoryThumbnail } from "../../thumbnailConfig";
import styles from "./CircleScrollbar.module.scss";
import { googleAnalyticsViewList } from "../../utils/reactGa";
import { usePartner } from "../../Contexts/PartnerContext";

type Props = {
    mini_banners: any;
    url: string;
};

export const CircleScrollbar = ({ mini_banners, url }: Props) => {
    let history = useHistory();
    const { partnerpid } = usePartner()
    const pagePath = history.location.pathname.substring(1)
    const ActiveTab = new URLSearchParams(history.location.search).get("category");
    const navigationHandler = (title: string, id: string) => {
        if (id === '191' || id === '190') {
            history.replace(`/${url}`);
        }
        else {
            history.replace(`/${url}?category=${title}&id=${id}`)
        }
    }
    const gaHandler = (category: any, index: number) => {
        googleAnalyticsViewList(pagePath, category, index)
    }

    return (
        <div className={styles.circleScrollbar} >
            <IonTitle className="ion-no-padding padb10 padl10 ion-margin-top primary-title-color">
                Categories
            </IonTitle>
            <div className={`${styles.circleCategoryCarouselContainer} scrollx`}>
                {mini_banners &&
                    mini_banners?.map((category: any, index: number) => (
                        <div className={`${styles.circleCarouselItemContainer} scroll-item ion-margin-bottom`} key={category.id}>
                            <IonButton className={`${ActiveTab === category.title ? `${styles.activeTab}` : ''}`} fill="clear" shape="round" onClick={() => { navigationHandler(category.title, category.type_id); gaHandler(category, index) }}>
                                <IonImg src={(category?.carousel_img || category?.icon) ? (category.carousel_img || category.icon) : partnerpid === 'esd' ? esdCategoryThumbnail :categoryThumbnail} />
                            </IonButton>
                            <h6 className="ion-text-center ion-no-margin ion-text-capitalize special-btn">{category.title}</h6>
                        </div>
                    ))}
            </div>
        </div >
    );
};
