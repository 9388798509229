import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    useIonActionSheet,
} from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { DealListCard } from "../deal-components/index.js";
import {
    chevronBack,
    filterOutline,
    swapVerticalOutline,
    close,
} from "ionicons/icons";
import { Footer, Header, NoData, SortFilter } from "../../../components";
import { getDealsList } from "../../../utils/getApiData";
import styles from "./DealsListPage.module.scss";
import { useUser } from "../../../Contexts/UserContext";
import ReactGA from 'react-ga4';

type PARAM = {
    category: string;
    subcategory: string;
};

export const DealsListPage = () => {
    const [dealsListData, setDealsListData] = useState<string[]>([]);
    const [showLoading, setShowLoading] = useState(true);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<any>();
    const [selectedSort, setSelectedSort] = useState<any>();
    const [displayCategory, setDisplayCategory] = useState<any>("");
    const [filters, setFilters] = useState<any>([]);
    const [subCategory, setSubCategory] = useState<any>([]);
    const [appliedFilter, setAppliedFilter] = useState<any>({});
    const [page, setPage] = useState(1);
    const { category, subcategory } = useParams<PARAM>();
    const user = useUser();
    const modal = useRef<HTMLIonModalElement>(null);
    const history = useHistory();

    const decodeURIComponentManual = (input:any) => {
        return input
          .replace('%25',/%/g);
    } 
    const filterParam = new URLSearchParams(history.location.search).get('filter');
    const sortParam = new URLSearchParams(history.location.search).get('sort');
    const [isMobileResolution, setIsMobileResolution] = useState(
        window.innerWidth <= 768
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileResolution(window.innerWidth <= 768);
            if(window.innerWidth <= 768){
                history.replace({})
                window.location.reload();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (filterParam) {
            setSelectedFilter(JSON.parse(filterParam))
            setAppliedFilter(JSON.parse(filterParam))
            setDealsListData([]);
            setPage(1);
        } else {
            setSelectedFilter(undefined);
            setAppliedFilter({});
            setDealsListData([]);
            setPage(1);
        }
    }, [filterParam]);

    useEffect(() => {
        if (sortParam) {
            setSelectedSort(sortParam)
            setDealsListData([]);
            setPage(1);
        } else {
            setSelectedSort(undefined);
            setDealsListData([]);
            setPage(1);
        }
    }, [sortParam])

    const RequestParams = {
        pageType:"home",
        type:"dealslist",
        sendSelectedTab: 1,
        menu_slug: decodeURIComponentManual(decodeURIComponent(subcategory)),
        page: page,
        ...(user?.profileid && { profileid: user?.profileid }),
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
    }, [history]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            closeModal();
            closeActionSheet();
        });
        return () => {
            unlisten();
        };
    }, [history])

    useEffect(() => {
        if (selectedFilter || selectedSort) {
            if (page === 1) {
                setDealsListData([])
            }
            (async () => {
                if (page === 1) {
                    setShowLoading(true);
                }
                try {
                    const {
                        data: { selectedTabItems },
                    } = await getDealsList(RequestParams, { ...(selectedFilter && { filters: selectedFilter }), ...(selectedSort && { sortby: selectedSort }), });

                    if (selectedTabItems && selectedTabItems.length > 0) {
                        selectedTabItems[0]?.filters && setDisplayCategory(selectedTabItems[0]?.filters[0]?.displayName);

                        selectedTabItems[0]?.filters && (selectedTabItems[0]?.filters[0]?.facets
                            ? setSubCategory(
                                selectedTabItems[0]?.filters[0]?.facets?.map(
                                    (item: any) => item?.name
                                )
                            )
                            : setSubCategory(selectedTabItems[0]?.filters[0]?.range));
                        selectedTabItems[0]?.filters && setFilters(selectedTabItems[0]?.filters);
                        setDealsListData([...dealsListData, ...selectedTabItems[0].deals]);
                        setDisableInfiniteScroll(selectedTabItems[0]?.deals?.length < 30);
                    } else {
                        if (dealsListData.length <= 0) {
                            setDealsListData(["NoData"])
                        } else {
                            setDisableInfiniteScroll(true)
                        }
                    }
                } catch {
                    setDealsListData(["NoData"]);
                }
                setShowLoading(false);
            })();
        } else {
            if (page === 1) {
                setShowLoading(true);
            }
            (async () => {
                try {
                    const {
                        data: { selectedTabItems },
                    } = await getDealsList(RequestParams, {});
                    if (selectedTabItems && selectedTabItems.length > 0) {
                        selectedTabItems[0]?.filters && setDisplayCategory(selectedTabItems[0]?.filters[0]?.displayName);

                        selectedTabItems[0]?.filters && (selectedTabItems[0]?.filters[0]?.facets
                            ? setSubCategory(
                                selectedTabItems[0]?.filters[0]?.facets?.map(
                                    (item: any) => item?.name
                                )
                            )
                            : setSubCategory(selectedTabItems[0]?.filters[0]?.range));
                        selectedTabItems[0]?.filters && setFilters(selectedTabItems[0]?.filters);
                        selectedTabItems[0]?.filters && setFilters(selectedTabItems[0]?.filters);
                        setDealsListData([...dealsListData, ...selectedTabItems[0].deals]);
                        setDisableInfiniteScroll(selectedTabItems[0].deals.length < 30);
                    } else {
                        if (dealsListData.length <= 0) {
                            setDealsListData(["NoData"])
                        } else {
                            setDisableInfiniteScroll(true)
                        }
                    }
                } catch (error) {
                    setDealsListData(["NoData"]);
                }
                setShowLoading(false);
            })();
        }
    }, [page, selectedFilter, selectedSort]);

    const getCategory = (category: any) => {
        setDisplayCategory(category.displayName);
        if (!category.facets) {
            setSubCategory(category.range);
        } else {
            setSubCategory(category?.facets?.map((item: any) => item?.name));
        }
    };
    const getCheckedItem = (event: any, item: any) => {
        if (Object.keys(appliedFilter).length === 0) {
            setAppliedFilter({ [displayCategory]: [item] });
        } else {
            if (appliedFilter[displayCategory]) {
                if (event.target.checked) {
                    setAppliedFilter({
                        ...appliedFilter,
                        [displayCategory]: [...appliedFilter[displayCategory], item],
                    });
                } else {
                    setAppliedFilter({
                        ...appliedFilter,
                        [displayCategory]: appliedFilter[displayCategory].filter(
                            (filterItem: any) => filterItem !== item
                        ),
                    });
                }
            } else {
                setAppliedFilter({ ...appliedFilter, [displayCategory]: [item] });
            }
        }
    };

    const isChecked = (item: any) => {
        if (Object.keys(appliedFilter).length !== 0) {
            if (appliedFilter[displayCategory]) {
                return appliedFilter[displayCategory].includes(item);
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const applyFilter = () => {
        const removeEmptyArray = Object.entries(appliedFilter).filter(
            (item: any) => item[1].length !== 0
        );
        if (removeEmptyArray.length !== 0) {
            setDealsListData([]);

            const selectedFilterObject = removeEmptyArray.reduce(
                (prev: any, item: any) => ({
                    ...prev,
                    [item[0]]: item?.[1]?.map((mapItem: any) => mapItem).join(","),
                }),
                {}
            );
            setSelectedFilter(selectedFilterObject);

            setAppliedFilter(
                removeEmptyArray.reduce(
                    (prev: any, item: any) => ({
                        ...prev,
                        [item[0]]: item?.[1]?.map((mapItem: any) => mapItem),
                    }),
                    {}
                )
            );
            setPage(1);
        }
        else if (!selectedFilter && Object.entries(appliedFilter).keys.length === 0 && removeEmptyArray.length === 0) { }
        else {
            setDealsListData([]);
            setAppliedFilter({});
            setSelectedFilter(undefined)
            setPage(1);
        }
        closeModal();
    };
    const resetFilter = () => {
        if (!selectedFilter && Object.entries(appliedFilter).keys.length === 0) { }
        else {
            setSelectedFilter(undefined);
            setAppliedFilter({});
            setDealsListData([]);
            setPage(1);
        }
        closeModal();
    };
    const applySort = (item: any) => {
        if (item !== selectedSort) {
            setPage(1);
            setSelectedSort(item);
            setDealsListData([]);
        }
    };
    const resetSort = (item: any) => {
        setPage(1);
        setSelectedSort(item);
        if (selectedSort) {
            setDealsListData([]);
        }
    };

    function closeModal() {
        modal.current?.dismiss();
    }
    function closeActionSheet() {
        dismiss();
    }
    const [present, dismiss] = useIonActionSheet();

    console.log(useIonActionSheet())

    const loadNextPage = (e: any) => {
        setTimeout(() => {
            e.target.complete();
        }, 1000);
        setPage(() => page + 1);
    };
    return (
        <IonPage className={styles.dealList}>
            <Header searchBar={true} />
            <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
                <IonToolbar className="header-text-color header-primary-bg-color">
                    <IonGrid fixed className="ion-no-padding">
                        <IonRow>
                            <IonButtons className={`${styles.btnContainer}`}>
                                <IonBackButton
                                    className="ion-no-padding"
                                    icon={chevronBack}
                                    defaultHref="/deals"
                                ></IonBackButton>
                            </IonButtons>
                            <IonTitle
                                className={`${styles.dealDetailTitle} ion-no-padding ion-text-center`}
                            >
                                {" "}
                                {decodeURIComponentManual(decodeURIComponent(category))}
                            </IonTitle>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className="scrollableDesign ion-hide-sm-up">
                    <div className="scrollable"></div>
                </div>
                <IonGrid fixed class="ion-hide-md-up">
                    <IonRow>
                        <IonCol size="6">
                            <IonButton
                                expand="block"
                                className={`ion-no-margin ${selectedSort ? 'primary-btn' : 'white-btn'}`}
                                onClick={() =>
                                    present({
                                        header: "Sort By",
                                        cssClass: ["custom-sheet"],
                                        buttons: [
                                            {
                                                text: "Relevance",
                                                role: "destructive",
                                                handler() {
                                                    resetSort(undefined);
                                                },
                                            },
                                            {
                                                text: "Popular",
                                                role: `${selectedSort === "popular" && "selected"
                                                    }`,
                                                handler: () => {
                                                    applySort("popular");
                                                },
                                            },
                                            {
                                                text: "Price Low",
                                                role: `${selectedSort === "pricelow" && "selected"
                                                    }`,
                                                handler: () => {
                                                    applySort("pricelow");
                                                },
                                            },
                                            {
                                                text: "Price High",
                                                role: `${selectedSort === "pricehigh" && "selected"
                                                    }`,
                                                handler: () => {
                                                    applySort("pricehigh");
                                                },
                                            },
                                            {
                                                text: "Discount Low",
                                                role: `${selectedSort === "discountlow" && "selected"
                                                    }`,
                                                handler: () => {
                                                    applySort("discountlow");
                                                },
                                            },
                                            {
                                                text: "Discount High",
                                                role: `${selectedSort === "discounthigh" &&
                                                    "selected"
                                                    }`,
                                                handler: () => {
                                                    applySort("discounthigh");
                                                },
                                            },
                                        ],
                                    })
                                }
                            >
                                <IonIcon slot="start" icon={swapVerticalOutline} />
                                Sort
                            </IonButton>
                        </IonCol>

                        {/* FILTER */}
                        <IonCol size="6">
                            <IonButton
                                id="open-modal" expand="block" className={`ion-no-margin ${selectedFilter ? 'primary-btn' : 'white-btn'}`} disabled={filters.length === 0}>
                                <IonIcon
                                    slot="start"
                                    icon={filterOutline}

                                />
                                Filter
                            </IonButton>

                            <IonModal
                                trigger="open-modal"
                                initialBreakpoint={1}
                                breakpoints={[1]}
                                ref={modal}
                                handleBehavior="cycle"
                            >
                                <IonContent
                                    className={`ion-bg-white ${styles.filterModal}`}
                                    scrollY={true}
                                >
                                    <IonItem class={styles.filterHeader}>
                                        <p className="ion-padding-start font14 font500 primary-title-color">
                                            All Filters
                                        </p>
                                        <IonIcon
                                            slot="end"
                                            className="dark"
                                            icon={close}
                                            onClick={() => closeModal()}
                                        />
                                    </IonItem>
                                    <IonGrid className="ion-no-padding">
                                        <IonRow className="ion-no-padding">
                                            <IonCol
                                                className={`ion-no-padding ${styles.subCategory}`}
                                                size="5"
                                            >
                                                {filters?.length !== 0 && filters?.map((item: any, index: any) => (
                                                    <IonList
                                                        key={index}
                                                        className="ion-no-padding ion-no-border"
                                                    >
                                                        <IonItem
                                                            className={`ion-no-padding ${styles.filterCategoryItem}`}
                                                            onClick={() => getCategory(item)}
                                                        >
                                                            <IonButton
                                                                className={`ion-no-margin ${item.displayName === displayCategory ? 'white-btn' : 'tertiary-btn'}`}
                                                            >
                                                                <IonLabel className="font12 font500 padl10">
                                                                    {item.displayName}
                                                                </IonLabel>
                                                            </IonButton>
                                                        </IonItem>
                                                    </IonList>
                                                ))}
                                            </IonCol>
                                            <IonCol
                                                className={`ion-no-padding ${styles.filterSubcategory}`}
                                                size="7"
                                            >
                                                {subCategory?.map((items: any, index: any) => (
                                                    <IonList
                                                        key={index}
                                                        className="ion-no-padding"
                                                    >
                                                        <IonItem className="ion-bg-white ion-no-padding">
                                                            <IonCheckbox
                                                                mode="ios"
                                                                checked={isChecked(items)}
                                                                className="ion-margin-start checkbox"
                                                                onClick={(event) =>
                                                                    getCheckedItem(event, items)
                                                                }
                                                            />
                                                            <IonLabel
                                                                className={`marl4 font12 ion-text-capitalize ${isChecked(items)
                                                                    ? "font500"
                                                                    : "font400"
                                                                    } ${styles.subCategoryLabel}`}
                                                            >
                                                                {items}
                                                            </IonLabel>
                                                        </IonItem>
                                                    </IonList>
                                                ))}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                                <IonFooter>
                                    <IonButton
                                        className={`ion-no-margin white-btn font14 font500`}
                                        expand="block"
                                        onClick={() => resetFilter()}
                                    >
                                        Reset
                                    </IonButton>
                                    <IonButton
                                        className={`ion-no-margin primary-btn font14 font500`}
                                        expand="block"
                                        onClick={() => applyFilter()}
                                    >
                                        Apply
                                    </IonButton>
                                </IonFooter>
                            </IonModal>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className={`${styles.title}`}>
                                <IonLabel className="font900 ion-no-padding">
                                    Deals
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid fixed>
                    <IonRow>
                        {
                            !isMobileResolution && filters.length !== 0 && <IonCol sizeMd="3.2" sizeLg="2.5" class="ion-hide-md-down">
                                <SortFilter data={filters} appliedFilter={appliedFilter} appliedSort={selectedSort} />
                            </IonCol>
                        }
                        {dealsListData.length !== 0 &&
                            (dealsListData[0] !== "NoData" ? (
                                <>
                                    <IonGrid fixed className={
                                            isMobileResolution
                                            ? styles.contentGridMobile
                                            : styles.contentGrid
                                        }>
                                        <IonRow>
                                            {dealsListData?.length !== 0 &&
                                                dealsListData?.map((dealDetail: any, index: any) => (
                                                    <IonCol size="6" sizeSm="4" sizeMd="4" className="card_pad8 card_pad5">
                                                        <DealListCard dealDetail={dealDetail} />
                                                    </IonCol>
                                                ))}
                                        </IonRow>
                                    </IonGrid>
                                    <IonInfiniteScroll
                                        onIonInfinite={(e) => loadNextPage(e)}
                                        threshold="100px"
                                        disabled={disableInfiniteScroll}
                                    >
                                        <IonInfiniteScrollContent
                                            loadingSpinner="bubbles"
                                            loadingText="Loading more data..."
                                        ></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                </>
                            ) : (
                                <NoData props={"No Data Found !"} />
                            ))}
                    </IonRow>
                </IonGrid>
                {dealsListData?.length > 0 && disableInfiniteScroll && <Footer />}
            </IonContent>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={showLoading}
                message={"Please wait..."}
            />
        </IonPage>
    );
};
