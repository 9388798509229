export function serializeToGetParams(object: String) {
  let str = '';
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (encodeURIComponent(object[key])) {
        if (str !== '') {
          str += '&';
        }
        str += key + '=' + encodeURIComponent(object[key]);
      }
    }
  }
  return str;
}

export function getAPI(domain: string, apiURL: string, paramsObj: any) {
  return `${domain + apiURL}?` + serializeToGetParams(paramsObj);
}