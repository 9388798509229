import ReactGA from "react-ga4";

export const googleAnalyticsBanner = (
  type: string,
  item: any,
  index: number
) => {
  ReactGA.gtag("event", "banner", {
    banner_index: index + 1,
    banner_title: item?.title,
    banner_redirectURL: item?.redirectURL,
    banner_type: type,
  });
};

export const googleAnalyticsCampaigns = (
  type: string,
  item: any,
  index: number
) => {
  ReactGA.gtag("event", "campaigns", {
    campaigns_index: index + 1,
    campaigns_storeName: item?.name,
    campaigns_title: item?.title,
    campaigns_redirectUrl: item?.url,
    campaigns_type: type,
  });
};

export const googleAnalyticsViewList = (
  type: string,
  item: any,
  index: number
) => {
  ReactGA.gtag("event", "view_list", {
    product_categories_index: index + 1,
    product_categories_title: item?.title,
    product_categories_type: type,
  });
};

export const googleAnalyticsViewMore = (item: any) => {
  ReactGA.gtag("event", "view_more", {
    product_carousel_title: item?.display_name,
    product_carousel_categories: item?.extra_value1,
    product_carousel_type: item?.type,
  });
};

export const googleAnalyticsViewItem = (item: any) => {
  ReactGA.gtag("event", "view_item", {
    item_title: item?.title,
    item_category: item?.category,
    item_department: item?.department,
    item_brand: item?.brand,
    item_price: item?.price,
    item_store: item?.store,
    item_type: item?.type,
    item_id: item?.typeID,
  });
};

export const googleAnalyticsViewStore = (item: any, url: string) => {
  ReactGA.gtag("event", "visit_store", {
    item_title: item?.title,
    item_brand: item?.brand,
    item_category: item?.category,
    item_price: item?.price,
    item_store: item?.store,
    item_type: item?.type,
    item_id: item?.typeID,
    item_redirectUrl: url,
  });
};

export const googleAnalyticsSearchTerm = (search: any, url: string) => {
  ReactGA.gtag("event", "search_term", {
    search_term: search,
    search_type: url,
  });
};

export const googleAnalyticsInterstitial = (button: any, store: string) => {
  ReactGA.gtag("event", "commission_info", {
    commission_btn: button,
    commission_store: store,
  });
};

export const googleAnalyticsShare = (name: string, title: any, url: string, profileid:any) => {
  ReactGA.gtag("event", "share_info", {
    shared_social_media_name: name,
    shared_product_title: title,
    shared_product_url: url,
    shared_user_profile_id: profileid
  });
};
