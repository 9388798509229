import { IonCol, IonContent, IonGrid, IonLoading, IonPage, IonRow, IonTitle } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header, NoData, TabHome } from "../../../components";
import { useUser } from "../../../Contexts/UserContext";
import { getAllCoupons } from "../../../utils/getApiData";
import styles from './CouponsHome.module.scss';
import ReactGA from 'react-ga4'
import { CouponListCard } from "../coupon-components";
import { HeaderLogoPopoverComponent } from "../../../components/HeaderLogoPopoverComponent/HeaderLogoPopoverComponent";

type CouponData = {
  display_name: string;
  coupons: {
    id: number;
    storeLogo: string;
    couponTitle: string;
    couponCode: string;
  }[];
}[]

export const CouponsHome = () => {
  const [couponsData, setCouponsData] = useState<CouponData>([]);
  const [noData, setNoData] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();
  const user = useUser();

  const RequestParams = {
    pageType: "home",
    type: "coupons",
    sendSelectedTab: 1,
    page: 1,
    ...(user?.profileid && { profileid: user?.profileid })
  }
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const { data: { selectedTabItems } } = await getAllCoupons(RequestParams);
        setCouponsData(selectedTabItems);
      }
      catch {
        setNoData(true)
      }
      setShowLoading(false)
    })()
  }, [])

  return (
    <IonPage className={styles.couponHome}>
       <HeaderLogoPopoverComponent />
      <TabHome />
      <Header searchBar={false} />
      {noData ? <NoData props={"No Data Found !"} /> : (<IonContent className={styles.content}>
        <IonGrid fixed className={styles.contentGrid}>
          {couponsData?.map((store, index) => (
            <div key={index}>
              <IonTitle class={`${styles.custom_title} ${styles.storeName} primary-title-color`}>{store.display_name}</IonTitle>
              <IonRow>
                <div className="ion-padding-bottom marb10 scrollx no-scroll">
                  {store?.coupons?.map((coupon, index) => (
                    <div className="scroll-item" key={index}>
                      <IonCol className="ion-no-padding">
                        <CouponListCard coupon={coupon} />
                      </IonCol>
                    </div>
                  ))}
                </div>

              </IonRow>
            </div>
          ))}
        </IonGrid>
      </IonContent>)
      }
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        message={'Please wait...'}
      />
    </IonPage >
  );
};


