export function convertSecToDate(timestamp: any) {
  const dateObject = new Date(timestamp);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = dateObject.getMonth();
  const day = dateObject.getDate().toString().padStart(2, "0"); 
  const year = dateObject.getFullYear();

  const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
  return formattedDate
}
