import React from 'react';
import { IonCard, IonCardContent, IonIcon, IonText, IonRow, IonCol } from '@ionic/react';
import { cash, informationCircle } from 'ionicons/icons';
import styles from "./balanceCard.module.scss";

interface Balances {
  text: string;
  amount: number;
  openCashbackBalanceDetailModal:any;
  isOpenable:boolean;
}

const BalanceCard:  React.FC<Balances> = ({text, amount,openCashbackBalanceDetailModal,isOpenable}) => {

  return (
    
    <div className={`${styles.balanceCards}`}>
     
        <IonCard className={`${styles.balanceCard}`}>
          <IonCardContent className={`${styles.balanceCardContent}`}>
            <div className={`${styles.balanceHeader}`}>
              <IonText color="medium">{text}</IonText>

              {isOpenable &&
              <IonIcon icon={informationCircle} color="medium" onClick={()=>{openCashbackBalanceDetailModal()}} />
              }
            </div>
            <div className={`${styles.balanceAmount}`}>
              <IonIcon icon={cash} color="success" />
              <IonText color="success" className={`${styles.amount}`}>Rs. {amount ? amount?.toFixed(2) : "0.0"}</IonText>
            </div>
          </IonCardContent>
        </IonCard>
  
     
    </div>
  );
};

export default BalanceCard;