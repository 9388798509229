import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { chevronBack } from "ionicons/icons";
import { close } from "ionicons/icons";
import { useHistory } from "react-router";
import { Footer, Header, NoData, SortFilter } from "../../../components/index";
import { getStoreList } from "../../../utils/getApiData";
import styles from "./StoreList.module.scss";
import { useUser } from "../../../Contexts/UserContext";
import { swapVerticalOutline } from "ionicons/icons";
import { filterOutline } from "ionicons/icons";
import ReactGA from "react-ga4";
import { StoreCard } from "../store-card/StoreCard";

export const StoreList = () => {
  const [categoryTitle, setCategoryTitle] = useState<any>("");
  const [storeData, setStoreData] = useState<any>([]);
  const [displayCategory, setDisplayCategory] = useState<any>("");
  const [filters, setFilters] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [payloadPage, setPayloadPage] = useState<number>(1);
  const [showLoading, setShowLoading] = useState(true);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [noData, setNoData] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [selectedSort, setSelectedSort] = useState<any>();
  const [sortList, setSortList] = useState<any>();

  // const { subcategory }: { subcategory: string } = useParams();
  const user = useUser();
  const modal = useRef<HTMLIonModalElement>(null);
  const history = useHistory();

  const filterParam = new URLSearchParams(history.location.search).get(
    "filter"
  );
  const sortParam = new URLSearchParams(history.location.search).get("sort");
  const [isMobileResolution, setIsMobileResolution] = useState(
    window.innerWidth <= 768
  );

  const RequestParams = {
    pageType: "home",
    sendSelectedTab: 1,
    page: payloadPage,
    ...(user?.profileid && { profileid: user?.profileid }),
    type: "stores",
  };

  useEffect(() => {
    if (filterParam) {
      setSelectedFilter(JSON.parse(filterParam));
      setAppliedFilter(JSON.parse(filterParam));
      setStoreData([]);
      setPayloadPage(1);
    } else {
      setSelectedFilter(undefined);
      setAppliedFilter({});
      setStoreData([]);
      setPayloadPage(1);
    }
  }, [filterParam]);

  useEffect(() => {
    if (sortParam) {
      setSelectedSort(sortParam);
      setStoreData([]);
      setPayloadPage(1);
    } else {
      setSelectedSort(undefined);
      setStoreData([]);
      setPayloadPage(1);
    }
  }, [sortParam]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobileResolution(window.innerWidth <= 768);
  //     if (window.innerWidth <= 768) {
  //       history.replace({});
  //       window.location.reload();
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: history.location.pathname + history.location.search,
    });
  }, [history]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      closeModal();
      closeActionSheet();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (selectedFilter || selectedSort) {
      if (payloadPage === 1) {
        setStoreData([]);
      }
      (async () => {
        if (payloadPage === 1) {
          setShowLoading(true);
        }
        try {
          const { data } = await getStoreList(
            { ...RequestParams },
            {
              ...(selectedFilter && { filters: selectedFilter }),
              ...(selectedSort && { sortby: selectedSort }),
            }
          );
          setCategoryTitle("BRAND CATEGORY");
          if (data?.selectedTabItems[0]?.stores) {
            let sortOptions: any[] = [];
            Object.entries(data?.selectedTabItems[0]?.sorts).forEach(
              ([key, value]) => {
                sortOptions.push({ text: value, sortType: key });
              }
            );

            sortOptions && setSortList(sortOptions);
            data?.selectedTabItems[0]?.stores &&
              setStoreData((prevData: []) => [
                ...prevData,
                ...data?.selectedTabItems[0]?.stores,
              ]);
            data?.selectedTabItems[0]?.filters &&
              setFilters(data?.selectedTabItems[0]?.filters);
            data?.selectedTabItems[0]?.filters[0]?.facets &&
              setSubCategory(data?.selectedTabItems[0]?.filters[0]?.facets);
            data?.selectedTabItems[0]?.filters &&
              setDisplayCategory(
                data?.selectedTabItems[0]?.filters[0]?.displayName
              );
            setDisableInfiniteScroll(
              data?.selectedTabItems[0]?.stores.length < 30
            );
          } else {
            if (storeData.length <= 0) {
              setNoData(true);
            } else {
              setDisableInfiniteScroll(true);
            }
          }
        } catch (e) {
          setNoData(true);
        }
        setShowLoading(false);
      })();
    } else {
      if (payloadPage === 1) {
        setShowLoading(true);
      }
      (async () => {
        try {
          const { data } = await getStoreList(RequestParams, {});
          setCategoryTitle("BRAND CATEGORY");
          if (data?.selectedTabItems[0]?.stores) {
            let sortOptions: any[] = [];
            Object.entries(data?.selectedTabItems[0]?.sorts).forEach(
              ([key, value]) => {
                sortOptions.push({ text: value, sortType: key });
              }
            );

            sortOptions && setSortList(sortOptions);
            data?.selectedTabItems[0]?.stores &&
              setStoreData((prevData: []) => [
                ...prevData,
                ...data?.selectedTabItems[0]?.stores,
              ]);
            data?.selectedTabItems[0]?.filters &&
              setFilters(data?.selectedTabItems[0]?.filters);
            data?.selectedTabItems[0]?.filters[0]?.facets &&
              setSubCategory(data?.selectedTabItems[0]?.filters[0]?.facets);
            data?.selectedTabItems[0]?.filters &&
              setDisplayCategory(
                data?.selectedTabItems[0]?.filters[0]?.displayName
              );
            setDisableInfiniteScroll(
              data?.selectedTabItems[0]?.stores.length < 30
            );
          } else {
            if (storeData.length <= 0) {
              setNoData(true);
            } else {
              setDisableInfiniteScroll(true);
            }
          }
        } catch (e) {
          console.log(e);
          setNoData(true);
        }
        setShowLoading(false);
      })();
    }
  }, [payloadPage, selectedFilter, selectedSort]);

  const loadData = (ev: any) => {
    setPayloadPage((prevPage) => prevPage + 1);
    setTimeout(() => {
      ev.target.complete();
    }, 1000);
  };

  const getCategory = (category: any) => {
    if (!category.facets) {
      setSubCategory(category.facets);
    }
  };
  const getCheckedItem = (event: any, item: any) => {
    if (Object.keys(appliedFilter).length === 0) {
      setAppliedFilter({ [displayCategory]: [item] });
    } else {
      if (appliedFilter[displayCategory]) {
        if (event.target.checked) {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: [...appliedFilter[displayCategory], item],
          });
        } else {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: appliedFilter[displayCategory].filter(
              (filterItem: any) => filterItem !== item
            ),
          });
        }
      } else {
        setAppliedFilter({ ...appliedFilter, [displayCategory]: [item] });
      }
    }
  };

  const isChecked = (item: any) => {
    if (Object.keys(appliedFilter).length !== 0) {
      if (appliedFilter[displayCategory]) {
        return appliedFilter[displayCategory].includes(item);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const applyFilter = () => {
    const removeEmptyArray = Object.entries(appliedFilter).filter(
      (item: any) => item[1].length !== 0
    );
    if (removeEmptyArray.length !== 0) {
      setStoreData([]);
      const selectedFilterObject = removeEmptyArray.reduce(
        (prev: any, item: any) => ({
          ...prev,
          [item[0]]: item?.[1]?.map((mapItem: any) => mapItem).join(","),
        }),
        {}
      );
      setSelectedFilter(selectedFilterObject);

      setAppliedFilter(
        removeEmptyArray.reduce(
          (prev: any, item: any) => ({
            ...prev,
            [item[0]]: item?.[1]?.map((mapItem: any) => mapItem),
          }),
          {}
        )
      );
      setNoData(false);
      setPayloadPage(1);
    } else if (
      !selectedFilter &&
      Object.entries(appliedFilter).keys.length === 0 &&
      removeEmptyArray.length === 0
    ) {
    } else {
      setStoreData([]);
      setAppliedFilter({});
      setSelectedFilter(undefined);
      setPayloadPage(1);
    }
    closeModal();
  };

  const resetFilter = () => {
    if (!selectedFilter && Object.entries(appliedFilter).keys.length === 0) {
    } else {
      setNoData(false);
      setSelectedFilter(undefined);
      setAppliedFilter({});
      setStoreData([]);
      setPayloadPage(1);
    }
    closeModal();
  };

  //// Sorts ///////
  const createSortButton = (text: any, sortType: any) => {
    return {
      text: text,
      role: selectedSort === sortType ? "selected" : "",
      handler: () => applySort(sortType),
    };
  };

  const createSortButtons = (sortOptions: any) => {
    const buttons = sortOptions?.map((option: { text: any; sortType: any }) =>
      createSortButton(option.text, option.sortType)
    );
    return buttons;
  };
  const sortOptionsButtons = createSortButtons(sortList ? sortList : []);

  const dynamicSortOptions = {
    header: "Sort By",
    cssClass: ["custom-sheet"],
    buttons: [
      // {
      //   text: "Relevance",
      //   role: selectedSort === "relevance" ? "selected" : "",
      //   handler: () => resetSort(undefined),
      // },
      ...sortOptionsButtons,
    ],
  };

  const applySort = (item: any) => {
    if (item !== selectedSort) {
      setPayloadPage(1);
      setSelectedSort(item);
      setStoreData([]);
    }
  };
  const resetSort = (item: any) => {
    setPayloadPage(1);
    setSelectedSort(item);
    if (selectedSort) {
      setStoreData([]);
    }
  };

  //// End Sorts ///////
  function closeModal() {
    modal.current?.dismiss();
  }

  function closeActionSheet() {
    dismiss();
  }
  const [present, dismiss] = useIonActionSheet();

  return (
    <IonPage className={styles.storeList}>
      <Header searchBar={false} />
      <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed>
            <IonRow>
              <IonButtons>
                <IonBackButton
                  icon={chevronBack}
                  defaultHref="/shopping"
                ></IonBackButton>
              </IonButtons>
              <IonTitle className={styles.productListTitle}>
                {categoryTitle || "BRAND CATEGORY"}
              </IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.content}>
        <div className="scrollableDesign ion-hide-sm-up">
          <div className="scrollable"></div>
        </div>
        <IonGrid fixed class="ion-hide-md-up">
          {/* SORT BY */}
          <IonRow>
            <IonCol size="6">
              <IonButton
                expand="block"
                className={`ion-no-margin ${
                  selectedSort ? "primary-btn" : "white-btn"
                }`}
                onClick={() => present(dynamicSortOptions)}
                disabled={!sortList || sortList.length === 0}
              >
                <IonIcon slot="start" icon={swapVerticalOutline} />
                Sort
              </IonButton>
            </IonCol>

            {/* FILTER */}
            <IonCol size="6">
              <IonButton
                id="open-modal"
                expand="block"
                className={`ion-no-margin ${
                  selectedFilter ? "primary-btn" : "white-btn"
                }`}
                disabled={filters.length === 0}
              >
                <IonIcon slot="start" icon={filterOutline} />
                Filter
              </IonButton>

              <IonModal
                trigger="open-modal"
                initialBreakpoint={1}
                breakpoints={[1]}
                ref={modal}
                handleBehavior="cycle"
              >
                <IonContent
                  className={`ion-bg-white ${styles.filterModal}`}
                  scrollY={true}
                >
                  <IonItem class={styles.filterHeader}>
                    <p className="ion-padding-start font14 font500 primary-title-color">
                      All Filters
                    </p>
                    <IonIcon
                      slot="end"
                      icon={close}
                      className="dark"
                      onClick={() => closeModal()}
                    />
                  </IonItem>
                  <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                      <IonCol
                        className={`ion-no-padding ${styles.subCategory}`}
                        size="5"
                      >
                        {filters?.map((item: any) => (
                          <IonList
                            key={item.displayName}
                            className="ion-no-padding ion-no-border"
                          >
                            <IonItem
                              className={`ion-no-padding ${styles.filterCategoryItem}`}
                              onClick={() => getCategory(item)}
                            >
                              <IonButton
                                className={`ion-no-margin ${
                                  item.displayName === displayCategory
                                    ? "white-btn"
                                    : "tertiary-btn"
                                }`}
                              >
                                <IonLabel className="font12 font500 padl10 ">
                                  {item.displayName}
                                </IonLabel>
                              </IonButton>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                      <IonCol
                        className={`ion-no-padding ${styles.filterSubcategory}`}
                        size="7"
                      >
                        {subCategory?.map((items: any) => (
                          <IonList key={items.name} className="ion-no-padding">
                            <IonItem className="ion-bg-white ion-no-padding">
                              <IonCheckbox
                                mode="ios"
                                checked={isChecked(items.name)}
                                className="ion-margin-start checkbox"
                                onClick={(event) =>
                                  getCheckedItem(event, items.name)
                                }
                              />
                              <IonLabel
                                className={`marl4 font12 ion-text-capitalize ${
                                  isChecked(items.name) ? "font500" : "font400"
                                } ${styles.subCategoryLabel}`}
                              >
                                {items.name}
                              </IonLabel>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>
                <IonFooter>
                  <IonButton
                    className={`ion-no-margin white-btn font14 font500`}
                    expand="block"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </IonButton>
                  <IonButton
                    className={`ion-no-margin font14 font500 priimary-btn`}
                    expand="block"
                    onClick={() => applyFilter()}
                  >
                    Apply
                  </IonButton>
                </IonFooter>
              </IonModal>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid fixed>
          <IonRow>
            {!isMobileResolution && filters.length !== 0 && (
              <IonCol sizeMd="3.2" sizeLg="2.5" class="ion-hide-md-down">
                <SortFilter
                  data={filters}
                  appliedFilter={appliedFilter}
                  appliedSort={selectedSort}
                />
              </IonCol>
            )}
            {noData ? (
              <NoData props={"No Data Found !"} />
            ) : (
              <>
                <IonGrid
                  fixed
                  className={
                    isMobileResolution
                      ? styles.contentGridMobile
                      : styles.contentGrid
                  }
                >
                  <IonRow>
                    {storeData &&
                      storeData?.map((storeDetail: any, index: number) => (
                        <IonCol
                          className="ion-no-padding"
                          size="6"
                          sizeSm="4"
                          sizeMd="4"
                          key={index}
                        >
                          <StoreCard props={storeDetail} index={index} />
                        </IonCol>
                      ))}
                  </IonRow>
                </IonGrid>
                <IonInfiniteScroll
                  onIonInfinite={loadData}
                  disabled={disableInfiniteScroll}
                >
                  <IonInfiniteScrollContent
                    color="primary"
                    loadingSpinner="crescent"
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </>
            )}
          </IonRow>
          {storeData.length > 0 && disableInfiniteScroll && <Footer />}
        </IonGrid>
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />
    </IonPage>
  );
};
