import { IonItem, IonText } from "@ionic/react";
import styles from "./ProductSpecs.module.scss"

type Props = {
  specs: any;
};

export const ProductSpecs = ({ specs }: Props) => {
  return <div className={styles.productSpecs}>
    {specs && specs?.map((spec: any) =>
    (<IonItem >
      <IonText className="item-text-color">{spec.name}</IonText>
      <IonText className="item-text-color">{spec.value}</IonText>
    </IonItem>
    )
    )}

  </div>;
};
