import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import styles from './PriceCompare.module.scss'
import { googleAnalyticsViewStore } from "../../../../utils/reactGa";
import { esdCategoryThumbnail, categoryThumbnail } from "../../../../thumbnailConfig";
import { usePartner } from "../../../../Contexts/PartnerContext";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { chevronDown, chevronUp, star, starHalfOutline, starOutline } from "ionicons/icons";

type Props = {
  comparison: any;
};
export const PriceCompare = ({ comparison }: Props) => {
  const { partnerPid } = usePartner();
  const history = useHistory();
  const [arrow, setArrow] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    var obj: { [key: string]: boolean } = {};
    comparison?.results?.forEach((item: any) => {
      const store = item?.store;
      obj[store] = false;
    });

    setArrow(obj);
  }, [comparison]);

  const groupedData = comparison?.results?.reduce((acc: any, item: any) => {
    const store = item?.store;
    if (!acc.hasOwnProperty(store)) {
      acc[store] = { store, items: [] };
    }
    acc[store].items.push(item);
    return acc;
  }, {});

  const sortedGroups = groupedData && Object.values(groupedData)

  const gaHandler = (url: string) => {
    googleAnalyticsViewStore(comparison?.logParams, url);
  }

  const getRating = (rating: number) => {
    let arr: any = [];
    for (let i = 1; i <= 5; i++) {
      if (i < rating) {
        arr = [...arr, 'full']
      }
      else if (!Number.isInteger(rating) && (i - 1) < rating && rating < i) {
        arr = [...arr, 'half']
      } else {
        arr = [...arr, 'blank']
      }
    }
    return arr
  }

  const redirectHandler = (store: any, url: any, earnUpto: any, title: string) => {
    if (store === 'amazon' || store === 'flipkart') {
      history.push({ pathname: `/commission/${store}`, search: `url=${encodeURIComponent(url)}&noCashback=${earnUpto.noCashback}&displayText=${encodeURIComponent(earnUpto.displayText)}&title=${encodeURIComponent(title)}` })
    } else {
      window.open(url, '_blank')
    }
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdCategoryThumbnail : categoryThumbnail;
  }

  return (
    <div className={`${styles.PriceCompare}`}>
      <IonGrid className="ion-no-padding ion-margin-top">
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonAccordionGroup>
              {sortedGroups && sortedGroups?.map((group: any, index: number) => (
                <IonAccordion key={index} toggleIcon=''>
                  <IonItem
                    slot="header"
                    className="ion-no-margin ion-no-padding"
                    lines="none"
                    onClick={() => {
                      setArrow((prevArrow) => {
                        const updatedArrow = { ...prevArrow };
                        Object.keys(updatedArrow).forEach((key) => {
                          if (key !== group?.store) {
                            updatedArrow[key] = false;
                          }
                        });
                        updatedArrow[group?.store] = !prevArrow[group?.store];
                        return updatedArrow;
                      });
                    }}
                  >
                    <IonCard className="ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding">
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeLg="6" sizeMd="5" className={`${styles.custom_item}`}>
                            <div>
                              <img src={group.items[0].store_logo} alt='' onError={(e) => handleImageFailure(e)} />
                              {
                                group.items[0]?.rating && <div className="d-flex padl10 padb10 ion-hide-md-down">
                                  <IonLabel className="padr10" color="primary">Rating</IonLabel>
                                  {
                                    getRating(group?.items[0]?.rating)?.map((item: string, index: number) => {
                                      if (item === 'full') {
                                        return <IonIcon icon={star} color='primary' key={index}></IonIcon>
                                      } else if (item === 'half') {
                                        return <IonIcon icon={starHalfOutline} color='primary' key={index}></IonIcon>
                                      } else {
                                        return <IonIcon icon={starOutline} color='primary' key={index}></IonIcon>
                                      }
                                    })
                                  }
                                </div>
                              }
                            </div>
                            <span className={`${styles.accordionTitle} ion-hide-md-down`}>
                              <IonText className={`${styles.itemTitle}`}>{group.items[0].title}</IonText>
                            </span>
                          </IonCol>
                          <IonCol>
                            <IonText className={`${styles.price} ion-no-margin`}>
                              <div>
                                {group.items[0].price === 0 ? 'Check Price' : (
                                  <strong className={group.items[0]?.rewards_campaign?.displayText ? `font900 font16` : `${styles.price} font900 font16`}>{group.items[0].priceStr}</strong>
                                )}
                                {group?.items?.[0].origPrice > group?.items?.[0].finalPrice ? (
                                  <>
                                    <span className={`${styles.offer} ion-hide-md-down`}>
                                      <span className={`${styles.originalPrice} padl10 padr10`}>
                                        {group?.items?.[0].origPriceStr}
                                      </span>
                                      <IonLabel color='success'>
                                        (
                                        {Math.round(
                                          ((group?.items?.[0].origPrice - group?.items?.[0].finalPrice) /
                                            group?.items?.[0].origPrice) *
                                          100
                                        )}
                                        % off)
                                      </IonLabel>
                                    </span>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                              {
                                group.items[0]?.rewards_campaign?.displayText && <span className={`font12 font400 ${styles.cashbackText}`}>
                                  {group.items[0]?.rewards_campaign?.displayText}
                                </span>
                              }
                              <div className={`${styles.inlineContainer}`}>
                                <IonLabel className={`font12 font400 ${styles.variantsText}`}>Variants</IonLabel>
                                <IonIcon className={`${styles.padt}`} icon={arrow[group?.store] ? chevronUp : chevronDown} />
                              </div>
                            </IonText>
                          </IonCol>
                          <IonCol className="ion-text-right">
                            {group.items[0].instock === 1 ? (
                              <IonButton
                                className="ion-no-margin ion-no-padding ion-text-capitalize secondary-btn"
                                onClick={() => { gaHandler(group.items[0].url); redirectHandler(group.items[0].store, group.items[0].url, group.items[0].rewards_campaign, group.items[0].title) }}
                              >
                                Visit Store
                              </IonButton>
                            ) : (
                              <IonButton
                                shape="round"
                                className="ion-no-margin ion-no-padding disable-btn"
                                href={group.items[0].url}
                                target="_blank"
                              >
                                Out of Stock
                              </IonButton>
                            )}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonItem >
                  <div slot="content">
                    {group?.items?.map((item: any, index: any) => (
                      <IonCard className="ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding" key={index}>
                        <IonGrid>
                          <IonRow className="ion-align-items-center">
                            <IonCol sizeXl="6" sizeMd="5">
                              <span className={`${styles.itemTitleContainer}`}>
                                <IonText className={`${styles.itemTitle}`}>{item.title}</IonText>
                              </span>
                            </IonCol>
                            <IonCol className="ion-justify-content-center">
                              <IonText>
                                {item.price === 0 ? 'Check Price' : (
                                  <strong className="font900 font16">{item.priceStr}</strong>
                                )}
                                {
                                  item?.rewards_campaign?.displayText && <span className={`font400 ${styles.cashbackText}`}>
                                    {item?.rewards_campaign?.displayText}
                                  </span>
                                }
                              </IonText>
                            </IonCol>
                            <IonCol className="ion-text-right">
                              {item.instock === 1 ? (
                                <IonButton
                                  shape="round"
                                  className="ion-no-margin ion-no-padding ion-text-capitalize secondary-btn"
                                  onClick={() => { gaHandler(item.url); redirectHandler(item.store, item.url, item.rewards_campaign, item.title) }}
                                >
                                  Visit Store
                                </IonButton>
                              ) : (
                                <IonButton
                                  shape="round"
                                  className="ion-no-margin ion-no-padding disable-btn"
                                  href={item.url}
                                  target="_blank"
                                  onClick={() => gaHandler(item.url)}
                                >
                                  Out of Stock
                                </IonButton>
                              )}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    ))}
                  </div>
                </IonAccordion >
              ))}
            </IonAccordionGroup >

          </IonCol >
        </IonRow >
      </IonGrid >
    </div >
  );
};
